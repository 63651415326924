import imageOne from '../images/imageOne.jpg';
import imageTwo from "../images/imageTwo.jpg";
import imageThree from "../images/imageThree.jpg";


export const Slides = [
  {
    image: imageOne,
    alt: "home1",
    price: "300,000,000",
    Title: "Family Duplex in Mexico",
  },
  {
    image: imageTwo,
    alt: "home2",
    price: "200,000",
    Title: "Greenwich Mansion in Lagos",
  },
  {
    image: imageThree,
    alt: "home3",
    price: "50,000,0000",
    Title: "3-Bedroom Luxury Apartment in Lekki",
  },
];